import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';

import * as AdretailReactAD from '@adretail/react-ads';
import * as AdretailAD from '@adretail/ads';

window.React = React;
window.ReactDOM = ReactDOM;
window.classNames = classNames;
window.PropTypes = PropTypes;
window.R = R;

window.AdretailReactAD = AdretailReactAD;
window.AdretailAD = AdretailAD;
